import React from 'react';
import { useClassNameMapper } from 'react-bootstrap';
import { useUIDSeed } from 'react-uid';
import type { GridListProps, ListItem, ListProps } from '..';
import type { CSSPropertiesWithVars } from '../../../../helpers/styles/CSSPropertiesWithVarsHelper';
import { GridType } from '../enums';
import localStyles from './GridList.module.pcss';

/**
 * The `grid` variant for `List`. Displays a list of items in a Grid view.
 * When GridType is `flex`, the grid switches to a standard flexbox view.
 *
 * @author Willi Hyde, Jonathan Bridges, Luisina Santos
 */
const GridList = <T extends ListItem>({
  items = [],
  className,
  colProps = { xs: 1, sm: 2, md: 3, lg: 4, xl: 5 },
  gridType = GridType.GRID,
  itemSpacing,
  children
}: GridListProps<T> & ListProps<T>): React.ReactElement => {
  const cx = useClassNameMapper(localStyles);
  const uidSeed = useUIDSeed();

  /** useUIDSeed has issues with duplicate ids being generated here, so we'll include the index too to help ensure uniqueness */
  function getUid(id: string, i: number) {
    return uidSeed(`${id}-${i}`);
  }

  const spacing = {
    sm: '10px',
    md: '15px',
    lg: '25px',
    xl: '30px',
    xxl: '50px',
    none: '0'
  };

  const gridStylesContent: CSSPropertiesWithVars = {
    '--lia-grid-spacing': spacing[itemSpacing]
  };

  if (gridType === GridType.GRID) {
    Object.entries(colProps).forEach(([key, value]) => {
      gridStylesContent[`--lia-grid-num-${key}`] = value;
    });
  }

  return (
    <>
      {gridType === GridType.GRID && (
        <div className={cx('lia-grid-wrap')} style={gridStylesContent}>
          <div className={cx('lia-grid', className)}>
            {items.map((item, i) => (
              <React.Fragment key={getUid(item?.id, i)}>{children(item)}</React.Fragment>
            ))}
          </div>
        </div>
      )}
      {gridType === GridType.FLEX && (
        <div className={cx('lia-flex')} style={gridStylesContent}>
          {items.map((item, i) => (
            <React.Fragment key={getUid(item?.id, i)}>{children(item)}</React.Fragment>
          ))}
        </div>
      )}
    </>
  );
};

export default GridList;
